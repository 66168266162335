<template>
  <article class="productCard paginated-product-slider__card" :class="`card${card_number}`">
    <div
      class="product_card__top product_card_image_section relative grid"
      style="aspect-ratio: 16 / 9;"
    >
      <div
        class="product_card__top"
      :style="`
  background: url(${cardProduct.imageUrl}) no-repeat center;
  background-size: cover;aspect-ratio: 16 / 9;`">

        <product-card-special :special="cardProduct.special" :all="cardProduct.allOnSpecial" />
      </div>
    </div>
    <section class="product_card_text_section whitespace-normal flex flex-col gap-2 p-4" :style="`background: ${card?.background_colour.hex ?? '#ffffff'};`">
      <h3 class="flex paginated-product-slider__product gap-4 justify-between " :class="theme?.product_name?.font">
        <span class="flex-1 text-primary-600 text-lg font-bold">{{ cardProduct.name }}</span>
        <add-to-wish-list :product="product"></add-to-wish-list>
      </h3>
      <product-card-price
        :product="cardProduct"
        :regular_price_class="`line-through text-red-600 text-xxs`"
      />
      <h4 class="paginated-product-slider__sub-text whitespace-normal text-xxs " :class="theme?.sub_text?.font">
        {{ cardProduct.headline }}
      </h4>
    </section>
  </article>
</template>

<script>
import AddToWishList from "./AddToWishList.vue";
import ProductCardPrice from "./ProductCardPrice.vue";
import ProductCardSpecial from "./ProductCardSpecial.vue";
import CardProduct from "../models/CardProduct";
export default {
  props: ["product", "theme", "card", 'card_number'],
  components: {
    AddToWishList,
    ProductCardPrice,
    ProductCardSpecial,
  },
  data() {
    return {
      cardProduct: new CardProduct(this.product)
    }
  }
};
</script>
