<template>
  <section
    :style="`background-color: ${backgroundColourAsHash};`"
    ref="wrapper"
  >
    <div
      class="_2xl_container _2xl_px-0 _2xl_mx-auto grid pb-4"
      ref="container"
      :class="{ odd: !odd }"
    >
      <div>
        <h2
          class="heading px-4 pt-4 font-bold mb-4"
          :class="`${textColour} ${!odd ? 'text-right' : 'text-left'} ${
            small ? 'text-4xl' : 'text-4xl md_text-5xl'
          }`"
        >
          {{ title }}
        </h2>
      </div>

      <div class="w-full relative overflow-hidden">
        <div class="absolute top-0 bottom-0 -left-2 grid items-center">
          <button
            @click.prevent="decreaseChunkIndex"
            v-if="chunkIndex > 0"
            class="z-10"
          >
            <chevron-left-icon size="4rem" colour="#000000" />
          </button>
        </div>
        <div class="absolute top-0 bottom-0 -right-2 grid items-center">
          <button
            @click.prevent="increaseChunkIndex"
            v-if="showRightArrow"
            class="z-10"
          >
            <chevron-right-icon size="4rem" colour="#000000" />
          </button>
        </div>
        <div class="w-full relative overflow-hidden" ref="chunk_container">
          <div
            class="flex"
            :style="`width:${containerWidth * chunkedItems.length}px`"
            ref="list"
          >
            <div
              class="inline-flex w-full"
              v-for="(chunk, index) in chunkedItems"
              :key="`chuck${index}`"
            >
              <div
                class="
                  w-full
                  tagChunk
                  grid grid-cols-1
                  sm_grid-cols-2
                  md_grid-cols-3
                  p-4
                  gap-4
                "
                @touchstart="touchStartHandler"
                @touchend="touchEndHandler"
              >
                <div
                  v-for="(image, index) in chunk"
                  :key="index"
                  class="grid bg-primary-150 shadow cursor-pointer"
                  @click="modal_image = image.media_url"
                >
                  <img
                    :src="image.media_url"
                    alt=""
                    loading="lazy"
                    class="rounded-sm object-cover object-center h-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <transition name="fade">
    <div
      class="
        gallery__modal
        fixed
        top-0
        bottom-0
        left-0
        right-0
        bg-black
        grid
        place-items-center
        z-50
      "
      @click.self="modal_image = ''"
      v-if="modal_image"
    >
      <div
        class="w-5/6 max-w-4xl relative grid place-items-center"
        @click.self="modal_image = ''"
      >
        <div
          class="absolute top-0 bottom-0 -left-12 sm_-left-20 grid items-center"
          @click.self="modal_image = ''"
        >
          <button @click.prevent="previousImage">
            <chevron-left-icon size="4rem" colour="#ffffff" />
          </button>
        </div>
        <img :src="modal_image" alt="" class="rounded max-h-screen80" />
        <div
          class="
            absolute
            top-0
            bottom-0
            -right-12
            sm_-right-20
            grid
            items-center
          "
          @click.self="modal_image = ''"
        >
          <button @click.prevent="nextImage">
            <chevron-right-icon size="4rem" colour="#ffffff" />
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ChevronLeftIcon from "./ChevronLeftIcon.vue";
import ChevronRightIcon from "./ChevronRightIcon.vue";

export default {
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  props: {
    hashtag: String,
    title: String,
    theme_id: String|null,
  },
  data() {
    return {
      chunkIndex: 0,
      screenWidth: null,
      chunkedItems: [],
      page: 0,
      total: null,
      startX: null,
      items: [],
      containerWidth: null,
      itemsPerChuck: 1,
      modal_image: "",
      odd: true,
    };
  },
  computed: {
    showRightArrow() {
      return (
        (this.loadedAll && this.chunkIndex + 1 < this.chunkedItems.length) ||
        (!this.loadedAll &&
          this.total > (this.chunkIndex + 1) * this.itemsPerChuck)
      );
    },
    nextPage() {
      return this.page + 1;
    },
    loadedAll() {
      return this.total && this.total == this.items.length;
    },
    loadNext() {
      return !this.loadedAll && this.chunkedItems.length >= this.chunkIndex + 1;
    },
    backgroundColourAsHash() {
      return "#ffffff";
    },
    backgroundColour() {
      return "#ffffff";
    },
    textColour() {
      return "text-gray-900";
    },
    url() {
      return "/admin/instagram/hashtag";
    },
    currentIndex() {
      return this.items.findIndex((item) => item.media_url == this.modal_image);
    },
  },
  methods: {
    touchStartHandler(e) {
      console.log("started", e);
      this.startX = e.changedTouches[0].pageX;
    },
    touchEndHandler(e) {
      console.log("Ended", e);
      const endX = e.changedTouches[0].pageX;
      if (this.startX && Math.abs(this.startX - endX) > 50) {
        if (this.startX > endX) {
          this.increaseChunkIndex();
        } else {
          this.decreaseChunkIndex();
        }
      }

      this.startX = null;
    },
    loadMedia() {
      return new Promise((resolve, reject) => {
        this.show_spinner = true;
        axios({
          method: "post",
          url: this.url,
          data: {
            hashtag: this.hashtag,
          },
        })
          .then(({ data }) => {
            this.items = data.media.filter(
              (m) => m.media_type == (this.hashtag ? "IMAGE" : "CAROUSEL_ALBUM")
            );
            this.total = this.items.length;
            this.show_spinner = false;
            resolve();
          })
          .catch((error) => {
            this.show_spinner = false;
            reject(error);
          });
      });
    },
    setItemsPerChunk() {
      const screenWidth = this.$refs.container?.clientWidth;
      if (screenWidth < 600) {
        return 1;
      }
      if (screenWidth < 768) {
        return 2;
      } else {
        return 3;
      }
    },
    chunkItems() {
      return this.items.reduce((chunks, item, index) => {
        const chunkIndex = Math.floor(index / this.itemsPerChuck);
        if (index % this.itemsPerChuck == 0) {
          chunks[chunkIndex] = [];
        }
        chunks[chunkIndex].push(item);
        return chunks;
      }, []);
    },
    listElement() {
      return this.$refs.chunk_container;
    },
    listElementWidth() {
      return window.getComputedStyle(this.listElement()).width;
    },
    increaseChunkIndex() {
      console.log("wayydfj");
      if (this.chunkIndex + 1 <= this.chunkedItems.length) {
        if (this.loadNext) {
          this.containerWidth = this.$refs.container?.clientWidth;
          this.itemsPerChuck = this.setItemsPerChunk();
          this.chunkedItems = this.chunkItems();
          this.chunkIndex++;
          this.$nextTick(() => {
            this.listElement().scroll({
              top: 0,
              left: this.listElementWidth().replace("px", "") * this.chunkIndex,
              behavior: "smooth",
            });
          });
        } else {
          this.chunkIndex++;
          this.listElement().scroll({
            top: 0,
            left: this.listElementWidth().replace("px", "") * this.chunkIndex,
            behavior: "smooth",
          });
        }
      }
    },
    decreaseChunkIndex() {
      if (this.chunkIndex > 0) {
        this.chunkIndex--;
        this.listElement().scroll({
          top: 0,
          left:
            this.listElementWidth().replace("px", "") *
              this.chunkedItems.length -
            this.listElementWidth().replace("px", "") *
              (this.chunkedItems.length - this.chunkIndex),
          behavior: "smooth",
        });
      }
    },
    nextImage() {
      this.modal_image =
        this.currentIndex + 1 === this.total
          ? `${this.items[0].media_url}`
          : `${this.items[this.currentIndex + 1].media_url}`;
    },
    previousImage() {
      this.modal_image =
        this.currentIndex === 0
          ? `${this.items[this.total - 1].media_url}`
          : `${this.items[this.currentIndex - 1].media_url}`;
    },
  },
  mounted() {
    this.loadMedia().then(() => {
      this.containerWidth = this.$refs.container?.clientWidth;
      this.itemsPerChuck = this.setItemsPerChunk();
      this.chunkedItems = this.chunkItems();
      window.addEventListener("resize", () => {
        this.containerWidth = this.$refs.container?.clientWidth;
        this.itemsPerChuck = this.setItemsPerChunk();
        this.chunkedItems = this.chunkItems();
        this.chunkIndex = 0;
        this.listElement().scroll({
          top: 0,
          left: -this.listElement().scrollLeft,
          behavior: "smooth",
        });
      });
    });

    // const observer = new IntersectionObserver((entries, observer) => {
    //   if (entries[0].intersectionRatio > 0) {
    //     entries[0].target.style.animation = `slide 1s forwards ease-out`;
    //     observer.unobserve(entries[0].target);
    //   }
    // });
    // observer.observe(this.$refs.wrapper);
  },
};
</script>
<style scoped>
</style>
