<template>
  <div class="containable grid">
  <article class="sliderProductCard grid" :class="`c_${component_id}__card`">
    <div
    :class="`c_${component_id}__image_container grid`">
      <img 
        :src="cardProduct.imageUrl" 
        :class="`c_${component_id}__product_image`"
        >
      <product-card-special :special="cardProduct.special" :all="cardProduct.allOnSpecial" />
    </div>
    <section :class="`whitespace-normal grid c_${component_id}__text_container`">
      <h3 :class="`grid c_${component_id}__product_name`">
        {{ cardProduct.name }}
      </h3>
      <add-to-wish-list :product="product" :class="`c_${component_id}__heart`"></add-to-wish-list>
      <product-card-price
        :product="cardProduct"
        :price_group_class="`c_${component_id}__price_group`"
        :price_class="`c_${component_id}__price`"
        :regular_price_class="`c_${component_id}__regular_price`"
      />
      <div :class="`whitespace-normal grid c_${component_id}__sub_text`">
        {{ cardProduct.headline }}
      </div>
    </section>
  </article>
</div>
</template>

<script>
import AddToWishList from "./AddToWishList.vue";
import ProductCardPrice from "./ProductCardPrice.vue";
import ProductCardSpecial from "./ProductCardSpecial.vue";
import CardProduct from "../models/CardProduct";
export default {
  props: ["product", 'component_id', 'placeholder_image'],
  components: {
    AddToWishList,
    ProductCardPrice,
    ProductCardSpecial,
  },
  data() {
    return {
      cardProduct: new CardProduct(this.product, this.placeholder_image)
    }
  }
};
</script>
