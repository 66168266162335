<template>
  <div :class="`c_${component_id}__inner_container c_${component_id}__inner_container_${$theme_id ?? ''} relative min-h-80`" v-show="chunkedBlogArticles.length">
    <div class=" my-4" >
      <div
        class="paginated-product-slider__section -m-4"
        ref="container"
      >
        <section
      
        >
            <h2
              :class="`c_${component_id}__heading c_${component_id}__heading_${$theme_id ?? ''} heading`"
            >
              {{ heading }}
            </h2>

          <div class="w-full relative overflow-hidden">
            <div class="absolute top-0 bottom-0 -left-2 grid items-center">
              <button
                @click.prevent="decreaseChunkIndex"
                v-if="chunkIndex > 0"
                class="z-10"
                aria-label="slide images left"
              >
                <chevron-left-icon :class="`c_${component_id}__left_arrow c_${component_id}__left_arrow_${$theme_id ?? ''}`" />
              </button>
            </div>
            <div class="absolute top-0 bottom-0 -right-2 grid items-center">
              <button
                @click.prevent="increaseChunkIndex"
                v-if="!loadedAll || chunkIndex + 1 < chunkedBlogArticles.length"
                class="z-10"
                aria-label="slide images right"
              >
                <chevron-right-icon :class="`c_${component_id}__right_arrow c_${component_id}__right_arrow_${$theme_id ?? ''}`" />
              </button>
            </div>
            <ul
              class="w-full overflow-hidden whitespace-nowrap"
              :class="listElementClass"
            >
              <li
                class="inline-flex w-full"
                v-for="(chunk, index) in chunkedBlogArticles"
                :key="`chuck${index}`"
              >
                <div
                  class="
                    w-full
                    tagChunk
                    grid
                    p-4
                    gap-4
                  "
                  :class="colNumber"
                  @touchstart="touchStartHandler"
                  @touchend="touchEndHandler"
                >
                  <a
                    :href="`/blog/${blogArticle.slug}`"
                    v-for="(blogArticle, index) in chunk"
                    :key="blogArticle.id"
                    class="grid"
                  >
                    <blog-article-card :slider_component_id="component_id" :theme_id="theme_id" :component_id="blog_article_card_component_id" :blog_article="blogArticle" :card_number="(index + 1)" :card="item_settings?.[`card${index + 1}`]" />
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import ChevronLeftIcon from "./ChevronLeftIcon.vue";
import ChevronRightIcon from "./ChevronRightIcon.vue";
import BlogArticleCard from "./BlogArticleCard.vue";

export default {
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
    BlogArticleCard,
  },
  props: {
    heading: String,
    item: Object,
    item_settings: Object,
    odd: Number,
    small: Boolean,
    theme_id: String|null,
    container_tag: String,
    container_id: String,
    container_size: Number,
    component_id: String,
    blog_article_card_component_id: String,
  },
  data() {
    return {
      chunkIndex: 0,
      screenWidth: null,
      chunkedBlogArticles: [],
      page: 0,
      total: null,
      startX: null,
      blogArticles: [],
      colNumber: 'grid-cols-1',
    };
  },
  computed: {
    listElementClass() {
      return `chunk-${this.item ? this.item.slug : 'blog-articles'}`
    },
    nextPage() {
      return this.page + 1;
    },
    loadedAll() {
      return this.total && this.total == this.blogArticles.length;
    },
    loadNext() {
      return (
        !this.loadedAll && this.chunkedBlogArticles.length == this.chunkIndex + 1
      );
    },
    url() {
      return this.item 
        ? `/admin/blog_articles/${this.item.id}/list?page=${this.nextPage}`
        : `/admin/blog_articles/list?page=${this.nextPage}`;
    },
  },
  methods: {
    setColNumber() {
      let containerWidth = document.querySelector(
        `${this.container_tag}#${this.container_id}`
      ).clientWidth;

      if (containerWidth < 640) {
        this.colNumber = 'grid-cols-1';
      }
      else if (containerWidth < 768) {
        this.colNumber = 'grid-cols-2';
      }
      else if (containerWidth < 1024) {
        this.colNumber = 'grid-cols-3';
      } else {
        this.colNumber = 'grid-cols-4';
      }
    },
    touchStartHandler(e) {
      console.log("started", e);
      this.startX = e.changedTouches[0].pageX;
    },
    touchEndHandler(e) {
      console.log("Ended", e);
      const endX = e.changedTouches[0].pageX;
      if(this.startX && Math.abs(this.startX - endX) > 50) {
        e.preventDefault()
      }
      if (this.startX && Math.abs(this.startX - endX) > 50) {
        if (this.startX > endX) {
          this.increaseChunkIndex();
        } else {
          this.decreaseChunkIndex();
        }
      }

      this.startX = null;
    },
    loadBlogArticles() {
      return new Promise((resolve, reject) => {
        this.show_spinner = true;
        axios({
          method: "get",
          url: this.url,
        })
          .then(({ data }) => {
            console.log(data)
            this.total = data.total;
            this.page = data.current_page;
            this.blogArticles = [...this.blogArticles, ...data.data];
            this.show_spinner = false;
            resolve();
          })
          .catch((error) => {
            this.show_spinner = false;
            reject(error);
          });
      });
    },
    blogArticlesPerChunk() {
      let containerWidth = document.querySelector(
      `${this.container_tag}#${this.container_id}`
    ).clientWidth;
      if (containerWidth < 640) {
        return 1;
      }
      if (containerWidth < 768) {
        return 2;
      }
      if (containerWidth < 1024) {
        return 3;
      } else {
        return 4;
      }
    },
    chunkBlogArticles() {
      const blogArticlesPerChunk = this.blogArticlesPerChunk();
      return this.blogArticles.reduce((chunks, blogArticle, index) => {
        const chunkIndex = Math.floor(index / blogArticlesPerChunk);
        if (index % blogArticlesPerChunk == 0) {
          chunks[chunkIndex] = [];
        }
        chunks[chunkIndex].push(blogArticle);
        return chunks;
      }, []);
    },
    listElement() {
      return document.querySelector(`ul.${this.listElementClass}`);
    },
    listElementWidth() {
      // return window.getComputedStyle(this.listElement()).width;
      return this.$refs.container.clientWidth
    },
    increaseChunkIndex() {
      if (this.chunkIndex + 1 <= this.chunkedBlogArticles.length) {
        if (this.loadNext) {
          this.loadBlogArticles().then(() => {
            this.chunkedBlogArticles = this.chunkBlogArticles();
            this.chunkIndex++;
            this.$nextTick(() => {
              this.listElement().scroll({
                top: 0,
                left:
                  this.listElementWidth() * this.chunkIndex,
                behavior: "smooth",
              });
            });
          });
        } else {
          this.chunkIndex++;
          this.listElement().scroll({
            top: 0,
            left: this.listElementWidth() * this.chunkIndex,
            behavior: "smooth",
          });
        }
      }
    },
    decreaseChunkIndex() {
      if (this.chunkIndex > 0) {
        this.chunkIndex--;
        this.listElement().scroll({
          top: 0,
          left:
            this.listElementWidth() *
              this.chunkedBlogArticles.length -
            this.listElementWidth() *
              (this.chunkedBlogArticles.length - this.chunkIndex),
          behavior: "smooth",
        });
      }
    },
    handleResize() {
      this.setColNumber()
      this.chunkedBlogArticles = this.chunkBlogArticles();
      this.chunkIndex = 0;
      this.listElement().scroll({
        top: 0,
        left: -this.listElement().scrollLeft,
        behavior: "smooth",
      });
    }
  },
  mounted() {
    
    const container = document.querySelector(
      `${this.container_tag}#${this.container_id}`
    );
    const observer1 = new IntersectionObserver((entries, observer) => {
      if (entries[0].intersectionRatio > 0) {
        this.loadBlogArticles().then(() => {
         this.setColNumber()
        this.chunkedBlogArticles = this.chunkBlogArticles();
        window.addEventListener("resize", () => {
          this.handleResize()
        });
      });
      observer.unobserve(entries[0].target);
      }
    }, { rootMargin: "600px" });
    const observer2 = new IntersectionObserver((entries, observer) => {
      if (entries[0].intersectionRatio > 0) {
        entries[0].target.style.animation = `slide 1s forwards ease-out`;
        observer.unobserve(entries[0].target);
      }
    });
    observer1.observe(container);
    observer2.observe(container);
  },
};
</script>
<style scoped>
</style>
