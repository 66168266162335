<template>
  <div class="containable grid">
  <article class="sliderProductCard grid" :class="`c_${component_id}__card c_${component_id}__card_${$theme_id ?? ''} c_${slider_component_id}__card${card_number} card${card_number} c_${slider_component_id}__card${card_number}_${$theme_id ?? ''}`">
    <div
    :class="`c_${component_id}__image_container c_${component_id}__image_container_${$theme_id ?? ''} grid`">
      <img 
        :src="imageUrl" 
        :class="`c_${component_id}__image c_${component_id}__image_${$theme_id ?? ''}`"
        >
    </div>
    <section :class="`whitespace-normal grid c_${component_id}__text_container c_${component_id}__text_container_${$theme_id ?? ''}`">
      <div :class="`c_${component_id}__tag_container c_${component_id}__tag_container_${$theme_id ?? ''}`">
        <span v-for="tag in blog_article.tags" :key="tag.id" :class="`c_${component_id}__tag c_${component_id}__tag_${$theme_id ?? ''}`">{{ tag.name }}</span>
      </div>
      <h3 :class="`grid c_${component_id}__heading c_${component_id}__heading_${$theme_id ?? ''}`">
        {{ blog_article.heading }}
      </h3>
      <p :class="`whitespace-normal grid c_${component_id}__description c_${component_id}__description_${$theme_id ?? ''}`">
        {{ blog_article.meta_description }}
      </p>
      <p :class="`whitespace-normal grid c_${component_id}__author c_${component_id}__author_${$theme_id ?? ''}`">
        {{ blog_article.user.first_name }} {{ blog_article.user.last_name }}
      </p>
      <p :class="`whitespace-normal grid c_${component_id}__date c_${component_id}__date_${$theme_id ?? ''}`">
        {{ formatDate(blog_article.published_at) }}
      </p>
    </section>
  </article>
</div>
</template>

<script>
import dayjs from "dayjs";
import ImageService from "../services/imageService";

export default {
  props: ["blog_article", "card", 'card_number', 'component_id', 'slider_component_id', 'theme_id'],
  computed: {
    imageUrl() {
      return ImageService.url(this.blog_article.image, 'sm')
    }
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format("DD/MM/YYYY");
    },
  }
};
</script>
