<template>
  <div class="_2xl_container _2xl_px-0 _2xl_mx-auto grid pb-4">
    <h2 class="heading font-bold text-gray-900 mb-4 text-4xl md_text-5xl">
      {{ title }}
    </h2>
    <div ref="gallery" :class="classByImageNumber"></div>
  </div>
  <transition name="fade">
    <div
      class="
        gallery__modal
        fixed
        top-0
        bottom-0
        left-0
        right-0
        bg-black
        grid
        place-items-center
        z-50
      "
      @click.self="modal_image = ''"
      v-if="modal_image"
    >
      <div
        class="w-5/6 max-w-4xl relative grid place-items-center"
        @click.self="modal_image = ''"
      >
        <div
          class="absolute top-0 bottom-0 -left-12 sm_-left-20 grid items-center"
          @click.self="modal_image = ''"
        >
          <button @click.prevent="previousImage">
            <chevron-left-icon size="4rem" colour="#ffffff" />
          </button>
        </div>
        <img :src="modal_image" alt="" class="rounded max-h-screen80" />
        <div
          class="
            absolute
            top-0
            bottom-0
            -right-12
            sm_-right-20
            grid
            items-center
          "
          @click.self="modal_image = ''"
        >
          <button @click.prevent="nextImage">
            <chevron-right-icon size="4rem" colour="#ffffff" />
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ChevronLeftIcon from "./ChevronLeftIcon.vue";
import ChevronRightIcon from "./ChevronRightIcon.vue";
export default {
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  props: {
    hashtag: String,
    title: String,
  },
  data() {
    return {
      show_spinner: false,
      modal_image: "",
      images: [],
    };
  },
  computed: {
    classByImageNumber() {
      switch (this.images.length) {
        case 1:
          return "gallery__single";
        case 2:
          return "gallery__double";
        case 3:
        case 4:
        case 5:
          return "gallery__triple";
        default:
          return "gallery__mozaic";
      }
    },
    currentIndex() {
      return this.images.findIndex(
        (image) => image.media_url == this.modal_image
      );
    },
    numberOfImages() {
      return this.images.length;
    },
  },
  methods: {
    fetchImages() {
      this.show_spinner = true;
      axios({
        method: "post",
        url: this.hashtag ? "/admin/instagram/hashtag" : "/admin/instagram/media",
        data: {
          hashtag: this.hashtag,
        },
      })
        .then(({ data }) => {
          console.log(data);
          this.images = data.media.filter(
            (m) => m.media_type == (this.hashtag ? "IMAGE" : "CAROUSEL_ALBUM")
          );
          this.show_spinner = false;
          this.buildImages();
        })
        .catch((error) => {
          this.show_spinner = false;
        });
    },
    buildImages() {
      const images = [];
      Promise.all(
        this.images.map((iu) => {
          return new Promise((resolve, reject) => {
            const image = document.createElement("img");
            const imageContainer = document.createElement("div");
            image.src = iu.media_url;
            image.classList.add(
              "cursor-pointer",
              "object-cover",
              "h-full",
              "object-center",
              "rounded-sm"
            );
            image.onclick = () => {
              this.modal_image = image.src;
            };
            image.addEventListener(
              "load",
              () => {
                imageContainer.classList.add(
                  image.height < image.width ? "landscape" : "portrait"
                );
                imageContainer.append(image);
                images.push(imageContainer);
                resolve();
              },
              false
            );
          });
        })
      ).then(() => {
        this.$refs.gallery.append(...images);
      });
    },
    nextImage() {
      this.modal_image =
        this.currentIndex + 1 === this.numberOfImages
          ? `${this.images[0].media_url}`
          : `${this.images[this.currentIndex + 1].media_url}`;
    },
    previousImage() {
      this.modal_image =
        this.currentIndex === 0
          ? `${this.images[this.numberOfImages - 1].media_url}`
          : `${this.images[this.currentIndex - 1].media_url}`;
    },
  },
  mounted() {
    this.fetchImages();
  },
};
</script>